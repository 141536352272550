<template>

  <div class="row" ref="sendResponseForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
               <div class="row">
                 <div class="col-md-12">
                   <ValidationProvider
                     vid="sender_email"
                     rules="email"
                     name="The Sender Email"
                     v-slot="{ passed, failed,errors }">
                     <fg-input type="email"
                               :error="failed ? errors[0]: null"
                               label="Sender Email"
                               name="sender_email"
                               fou
                               v-model="formData.sender_email">
                     </fg-input>
                   </ValidationProvider>
                   <ValidationProvider
                           vid="subject"
                           rules=""
                           name="The Subject"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="Subject"
                               name="subject"
                               fou
                               v-model="formData.subject">
                     </fg-input>
                   </ValidationProvider>
                 </div>
               </div>
              </div>
              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <div class="form-group">
                  <label>Email Body</label>
                  <editor
                    v-model="formData.email_body"
                    :api-key="editorKey"
                    :init='editorConfig'
                  />
                </div>

              </div>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit(true))"
                      nativeType="submit"
                      type="default" wide>Save
            </l-button>
            <l-button @click="$router.push('/campaigns/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import FormGroupTextArea, {ImageUploader} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import {PrimeUploader} from '@/components';
import {PrimeVideoUploader} from '@/components';
import PrimePdfUploader from "../../components/PrimePdfUploader";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    PrimePdfUploader,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      formData: {
        subject: "",
        email_body: "",
        id: undefined,

      },
    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.sendResponseForm
    });
    this.formData.id = this.$route.params['id'];
    let data = {
      id: this.formData.id
    }
    this.axios.post("campaigns/get-email-template", data).then((response) => {
      this.loader.hide();
      this.formData = response.data;
    }).catch((error) => {
      if (error.response.status === 404) {
        this.entityNotFoundError = true;
        this.$notify({
          message: "Campaign Not Found",
          timeout: 2000,
          type: 'danger'
        });
      } else {
        console.error(error);
      }
    })
  },


  methods: {
    submit(isSave = false) {
      let request;
      let successMessage;
      this.submitting = true;
      request = this.axios.post("campaigns/add-email-template", this.formData);
      successMessage = "Email Template saved Successfully";

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        if (isSave){
          window.location.href = "/campaigns/add-email-template/" + JSON.parse(response.config.data).id;
        }else{
          this.$router.push("/campaigns/list");
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
  }
}
</script>
