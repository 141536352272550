var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"sendResponseForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"sender_email","rules":"email","name":"The Sender Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"email","error":failed ? errors[0]: null,"label":"Sender Email","name":"sender_email","fou":""},model:{value:(_vm.formData.sender_email),callback:function ($$v) {_vm.$set(_vm.formData, "sender_email", $$v)},expression:"formData.sender_email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"subject","rules":"","name":"The Subject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Subject","name":"subject","fou":""},model:{value:(_vm.formData.subject),callback:function ($$v) {_vm.$set(_vm.formData, "subject", $$v)},expression:"formData.subject"}})]}}],null,true)})],1)])]),_c('div',{staticClass:"col-md-12 com-sm-12 mb-2 mt-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email Body")]),_c('editor',{attrs:{"api-key":_vm.editorKey,"init":_vm.editorConfig},model:{value:(_vm.formData.email_body),callback:function ($$v) {_vm.$set(_vm.formData, "email_body", $$v)},expression:"formData.email_body"}})],1)])])]),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Submit ")]),_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"default","wide":""},on:{"click":function($event){$event.preventDefault();handleSubmit(_vm.submit(true))}}},[_vm._v("Save ")]),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/campaigns/list')}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }