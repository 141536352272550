<template>

  <div class="row" ref="campaignForm">

    <div class="col-xs-12  col-md-12  col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="name"
              rules="required"
              name="The name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Campaign Name"
                        name="name"
                        fou
                        v-model="formData.name">
              </fg-input>
            </ValidationProvider>

            <ValidationProvider
              vid="client_id"
              rules="required"
              name="The Client"
              v-slot="{ passed, failed,errors }">
              <fg-select
                name="client_id"
                size="large"
                filterable
                clearable
                placeholder="Client"
                :error="failed ? errors[0]: null"
                :input-classes="'select-default'"
                :label="'Client'"
                :list="clientList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.client_id">
              </fg-select>
            </ValidationProvider>

            <div class="form-group">
              <label>Logo</label>
              <el-tooltip placement="right" v-if="getBannerImageInfo()">
                <div slot="content">{{getBannerImageInfo()}}</div>
                <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
              </el-tooltip>
              <prime-uploader
                :preview-width="'200px'"
                :preview-height="'200px'"
                :elementNum="1"
                v-model="formData.logo"
              >
              </prime-uploader>
            </div>
            <div class="row">
              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <div class="form-group">
                  <label>Content</label>
                  <editor
                    v-model="formData.content"
                    :api-key="editorKey"
                    :init='editorConfig'
                  />
                </div>
              </div>
            </div>
            <h5>Shipping Information</h5>
            <ValidationProvider
              vid="address"
              rules=""
              name="The Address"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Address"
                        name="address"
                        fou
                        v-model="formData.address">
              </fg-input>
            </ValidationProvider>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  vid="state"
                  rules="required"
                  name="The State"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="state"
                    size="large"
                    filterable
                    clearable
                    placeholder="State"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'State'"
                    :list="statesList"
                    :listItemLabel="'name'"
                    @change="stateChanged"
                    :listItemValue="'isoCode'"
                    v-model="formData.state">
                  </fg-select>
                </ValidationProvider>
              </div>
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  vid="city"
                  rules="required"
                  name="The City"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="city"
                    size="large"
                    filterable
                    clearable
                    placeholder="City"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'City'"
                    :list="citiesList"
                    :listItemLabel="'name'"
                    :listItemValue="'name'"
                    v-model="formData.city">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  vid="postal_code"
                  rules="required"
                  name="The Postal Code"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Postal Code"
                            name="postal_code"
                            fou
                            v-model="formData.postal_code">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  vid="phone"
                  rules=""
                  name="The Phone"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Phone"
                            name="phone"
                            fou
                            v-model="formData.phone">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit(false))"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit(true))"
                      nativeType="submit"
                      type="default" wide>Save
            </l-button>
            <l-button @click="$router.push('/campaigns/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import {PrimeUploader} from '@/components';
import {PrimeVideoUploader} from '@/components';
import { Country, State, City }  from 'country-state-city';

extend("required", {
  message: "{_field_} is required"
});

export default {
  components: {FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor},
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        name: "",
        client_id: "",
        content: "",
        logo: "",
        address: "",
        city: "",
        state: "",
        postal_code: "",
        phone: "",
      },
      clientList: [],
      statesList: [],
      citiesList: [],
      defaultToAddress: null,
    };
  },
  watch: {
    formData: {
      handler: function (oldValue ,newValue) {
        if (this.formData.state === ""){
          this.formData.city = "";
        }
       this.citiesList = City.getCitiesOfState('US', this.formData.state);
        if(!this.citiesList.length){
          let state =this.statesList.find(state => state.isoCode === this.formData.state);
          this.citiesList.push({name:state.name});
          this.formData.city = state.name ;
        }
      },
      deep: true
    },
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.campaignForm
    });

    this.axios.post("campaigns/builder").then((response) => {
      this.clientList = response.data.clients;
      this.defaultToAddress = response.data.defaultToAddress;
      this.fillAddress();
      this.statesList = State.getStatesOfCountry('US');
      this.id = this.$route.params['id'];
      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Campaign";
        this.getCampaign();
      } else {
        this.editMode = false;
        this.formTitle = "Add Campaign";
        this.loader.hide();
      }

    }).catch((error) => {
    })

  },
  methods: {
    getCampaign() {
      let data = {
          'id' : this.id,
      };
      this.axios.post("campaigns/get", data).then((response) => {
        this.formData = response.data;
        this.fillAddress();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Campaign Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
        }
      })
    },

    submit(isSave = false) {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("campaigns/update/" + this.id, this.formData);
        successMessage = "Campaign Updated Successfully";
      } else {
        request = this.axios.post("campaigns/create", this.formData);
        successMessage = "Campaign Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        if (isSave){
          window.location.href = "/campaigns/edit/" + response.data.id;;
        }else{
          this.$router.push("/campaigns/list");
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.submitting = false;
          this.entityNotFoundError = false;
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          if(error.response.data && error.response.data.message){
            this.$notify({
              message: error.response.data.message,
              timeout: 4000,
              type: 'danger'
            });
          }
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    getBannerImageInfo() {
      if(this.mediaInfo && this.mediaInfo["campaign_image"]) {
        return this.mediaInfo["campaign_image"];
      } else {
        return "";
      }
    },
    fillAddress(){
      this.formData.address = this.formData.address ? this.formData.address : this.defaultToAddress.addressLines[0];
      this.formData.city = this.formData.city ? this.formData.city : this.defaultToAddress.cityTown;
      this.formData.state = this.formData.state ? this.formData.state : this.defaultToAddress.stateProvince;
      this.formData.postal_code = this.formData.postal_code ? this.formData.postal_code : this.defaultToAddress.postalCode;
      this.formData.phone = this.formData.phone ? this.formData.phone : this.defaultToAddress.phone;
    },
    stateChanged(){
      this.formData.city = "";
    },
  }
}
</script>

<style>
</style>
